/** @format */

import Grid from "@material-ui/core/Grid";
import { Button, Form } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  isPairDisabled,
  selectPairFormSerialNumber,
  selectPairFormValues,
} from "../../../redux/reducers/PairFormSlice/PairFormSelectors";
import { isTerminalUnpaired } from "../../../redux/reducers/TerminalSlice/terminalsSliceSelectors";
import { handlePairClick } from "../../../utils/common/pair/pairStatusHelpers";
import PairConfiguration from "./PairConfiguration";
import useStyles from "./index.styles";

const PairForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [pairForms] = Form.useForm();

  // read redux store states
  const pairFormSerialNumber = useAppSelector(selectPairFormSerialNumber);
  const terminalUnpaired = useAppSelector(
    isTerminalUnpaired(pairFormSerialNumber)
  );
  const pairFormValues = useAppSelector(selectPairFormValues);
  const pairBtnDisabled = useAppSelector(isPairDisabled);

  const handlePair = () => handlePairClick(dispatch, pairFormValues);
  const { pairingConfigurations } = useSelector(
    (state: any) => state.posDeviceSettingsReducer
  );

  useEffect(() => {
    dispatch({
      type: "GET_ALL_PAIRING_CONFIGURATIONS_SECTION_LIST",
    });
  }, []);

  return (
    <Grid
      container
      direction="column"
      className={`${classes.formContainer}  me-4 bg-white`}
      style={{ padding: "15px", paddingBottom: "30px" }}
    >
      <Form form={pairForms} className="categoryField">
        {/* <PaymentType /> */}
        <PairConfiguration />
        <Button
          className={classes.pairBtn}
          color="primary"
          disabled={pairBtnDisabled || terminalUnpaired}
          data-test-id="pairBtn"
          onClick={handlePair}
          type="primary"
        >
          Pair
        </Button>
      </Form>
    </Grid>
  );
};

export default PairForm;
