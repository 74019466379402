/** @format */

import { Form } from "antd";
import Skeleton from "react-loading-skeleton";

const PaymentConfigurationLoading = () => {
  return (
    <div className="row">
      <div className="col-md-6 col-lg-6">
        <div className="form-group categoryField">
          <Form.Item label={<Skeleton width={100} />} name="storeName">
            <Skeleton height={32} />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6 col-lg-6">
        <div className="form-group categoryField">
          <Form.Item label={<Skeleton width={100} />} name="storeName">
            <Skeleton height={32} />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6 col-lg-6">
        <div className="form-group categoryField">
          <Form.Item label={<Skeleton width={100} />} name="storeName">
            <Skeleton height={32} />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6 col-lg-6">
        <div className="form-group categoryField">
          <Form.Item label={<Skeleton width={100} />} name="storeName">
            <Skeleton height={32} />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-12 col-lg-12">
        <div className="form-group categoryField">
          <Form.Item label={<Skeleton width={100} />} name="storeName">
            <Skeleton height={32} />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-12 col-lg-12">
        <div className="form-group categoryField">
          <Form.Item label={<Skeleton width={100} />} name="storeName">
            <Skeleton height={32} />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfigurationLoading;
