/** @format */

import { Chip } from "@material-ui/core";
import { Button, Empty } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SPI_PAIR_STATUS } from "../../../definitions/constants/commonConfigs";
import { useAppDispatch } from "../../../redux/hooks";
import { ITerminalProps } from "../../../redux/reducers/TerminalSlice/interfaces";
import {
  getTitleFromStatus,
  handleUnPairClick,
} from "../../../utils/common/pair/pairStatusHelpers";
import useStyles from "./index.styles";
import { ITerminalList } from "./interfaces";

function TerminalList({ terminals }: ITerminalList): React.ReactElement {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const chipStyles = (status: string) => {
    switch (status) {
      case SPI_PAIR_STATUS.PairedConnected:
        return classes.chipConnected;
      case SPI_PAIR_STATUS.PairedConnecting:
        return classes.chipPending;
      case SPI_PAIR_STATUS.Unpaired:
        return classes.chipUnpaired;
      default:
        return classes.chipUnpaired;
    }
  };

  console.log("terminal list", terminals);

  return (
    <div className="row  d-flex justify-content-flex-start m-2">
      {terminals.length > 0 ? (
        terminals?.map((terminal: ITerminalProps, index) => (
          <div
            className="col-md-4 col-xxl-4 pb-3 grid-margin stretch-card"
            key={index}
          >
            <div className="card h-100 shadow border-0">
              <div className="card-body">
                <div className="template-demo">
                  <table className="table mb-0 ">
                    <tbody>
                      <tr>
                        <td className="ps-0">POS ID</td>
                        <td className="pe-0 text-end">{terminal.posId}</td>
                      </tr>
                      <tr>
                        <td className="ps-0">Pairing Status</td>
                        <td className="pe-0 text-end">
                          <Chip
                            size="small"
                            label={getTitleFromStatus(
                              terminal.status ??
                                SPI_PAIR_STATUS.PairedConnecting,
                              terminal.reconnecting ?? true
                            )}
                            className={chipStyles(
                              terminal.status ??
                                SPI_PAIR_STATUS.PairedConnecting
                            )}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">EFTPOS Address</td>
                        <td className="pe-0 text-end">
                          {terminal.deviceAddress}
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">Serial Number</td>
                        <td className="pe-0 text-end">
                          {terminal.serialNumber}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    {terminal.status == SPI_PAIR_STATUS.PairedConnected && (
                      <Button
                        onClick={() => handleUnPairClick(dispatch, terminal.id)}
                        danger
                        className="mt-2"
                      >
                        Unpair
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <Empty description="No Terminals !" />
      )}
    </div>
  );
}

export default TerminalList;
