/** @format */

import { ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { API } from "../helpers/baseURL";

export const getAllPairingConfigurationSectionEpic = (action$) =>
  action$.pipe(
    ofType("GET_ALL_PAIRING_CONFIGURATIONS_SECTION_LIST"),
    mergeMap((action) =>
      from(API.get(`/POSDeviceSetting/getAllPairTerminalDetailsList`)).pipe(
        mergeMap((response) => {
          return of({
            type: "GET_ALL_PAIRING_CONFIGURATIONS_SECTION_LIST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((error) =>
          of({
            type: "GET_ALL_PAIRING_CONFIGURATIONS_SECTION_LIST_FAILURE",
            payload: error?.response?.data?.message?.[0]?.message,
          })
        )
      )
    )
  );
export const getReleaseVersionEpic = (action$) =>
  action$.pipe(
    ofType("GET_RELEASE_VERSION_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/common/getPOSMobileReleaseVersion`)).pipe(
        mergeMap((response) => {
          return of({
            type: "GET_RELEASE_VERSION_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((error) =>
          of({
            type: "GET_RELEASE_VERSION_FAILURE",
            payload: error?.response?.data?.message?.[0]?.message,
          })
        )
      )
    )
  );
