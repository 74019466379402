/** @format */

import { Container } from "@material-ui/core";
import React from "react";
import { useAppSelector } from "../../redux/hooks";
import NoTerminalPage from "../NoTerminalPage";
import TerminalList from "./TerminalList";
import useStyles from "./index.styles";

const Terminals: React.FC = (props) => {
  const classes = useStyles();
  const terminals = useAppSelector((state) => state.terminals);
  const terminalList = Object.values(terminals);

  return (
    // <Layout>
    <Container maxWidth="md" className={classes.root}>
      {/* <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h6" component="h1">
            Terminals list
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            component={LinkRouter}
            to={PATH_PAIR}
          >
            + Pair terminal
          </Button>
        </Box>
      </Box> */}
      {terminalList.length <= 0 ? (
        <NoTerminalPage />
      ) : (
        <TerminalList terminals={terminalList} />
      )}
    </Container>
    // </Layout>
  );
};

export default Terminals;
