/** @format */

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Empty } from "antd";
import React from "react";
import useStyles from "./index.styles";

const NoTerminalPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid>
      <Grid item>
        <Paper className={classes.paper} elevation={0}>
          <Empty description="No Terminals Paired " />

          {/* <Button
            variant="contained"
            color="primary"
            component={Link}
            onClick={}
            // to={"/settings/eftpos-terminal-settings"}
        >
            + Pair terminal
          </Button> */}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default NoTerminalPage;
