/** @format */

import spiService from "../../../services/spiService";

export function initiatePurchase(
  instanceId: string,
  purchaseAmount: number,
  tipAmount: number,
  cashoutAmount: number,
  surchargeAmount: number,
  promptForCashout: boolean,
  posRefId: string
): void {
  spiService.initiatePurchaseTransaction(
    instanceId,
    posRefId,
    purchaseAmount,
    tipAmount,
    cashoutAmount,
    promptForCashout,
    surchargeAmount
  );
}

export function recoverTransaction(
  instanceId: string,
  posRefId: string,
  transactionType: string
): void {
  spiService.recoverTransaction(instanceId, posRefId, transactionType);
}

export function initiateMotoPurchase(
  instanceId: string,
  purchaseAmount: number,
  surchargeAmount: number
): void {
  const posRefId = `moto-${new Date().toISOString()}`;
  spiService.initiateMotoPurchaseTransaction(
    instanceId,
    posRefId,
    purchaseAmount,
    surchargeAmount
  );
}

export function initiateCashoutOnlyTx(
  instanceId: string,
  purchaseAmount: number,
  surchargeAmount: number
): void {
  const posRefId = `cashout-${new Date().toISOString()}`;
  spiService.initiateCashoutOnlyTxTransaction(
    instanceId,
    posRefId,
    purchaseAmount,
    surchargeAmount
  );
}

export function initiateRefundTx(
  instanceId: string,
  refundAmount: number,
  posRefId: string
): void {
  spiService.initiateRefundTxTransaction(instanceId, posRefId, refundAmount);
}
export function cancelTransaction(instanceId: string): void {
  spiService.spiCancelTransaction(instanceId);
}
export function setTerminalToIdle(instanceId: string): void {
  spiService.spiSetTerminalToIdle(instanceId);
}
