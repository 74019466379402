/** @format */

import { Button, Result } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../mx51/redux/hooks";
import { updateSelectedTerminal } from "../../mx51/redux/reducers/SelectedTerminalSlice/selectedTerminalSlice";
import selectedTerminalIdSelector from "../../mx51/redux/reducers/SelectedTerminalSlice/selectedTerminalSliceSelector";
import {
  pairedConnectedTerminalList,
  terminalInstance,
} from "../../mx51/redux/reducers/TerminalSlice/terminalsSliceSelectors";
import {
  removeLastTransactionData,
  setLastTransactionData,
} from "../../mx51/utils/common/helpers";
import { recoverTransaction } from "../../mx51/utils/common/purchase/purchaseHelper";

const IncompleteTransactionInTerminalMessageHeading =
  "Incomplete Last Transaction !";
const IncompleteTransactionInTerminalMessageSubHeading =
  "Do you want to continue with that previous order ?";
const recoverTransactionMessageHeading = "Transaction Incomplete !";
const recoverTransactionMessageSubHeading =
  "Do you wish to recover the transaction ?";

const UnknownTransaction = ({
  isUnknowEftposTransactionModalOpen,
  setIsUnknownEftpostTransactionModalOpen,
}) => {
  const selectedTerminal = useSelector(selectedTerminalIdSelector);
  const currentTerminal = useSelector(terminalInstance(selectedTerminal));
  const terminals = useSelector(pairedConnectedTerminalList);
  const termianls = useAppSelector((state) => state.terminals);

  const { search } = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(search);

  const amountFromParam = queryParams.get("amount");
  const transactionData = queryParams.get("transactionData");
  const posRefId = localStorage.getItem("posRefId");

  const navigate = useNavigate();
  const [
    wasTransactionCompletedInTerminal,
    setWasTransactionCompletedInTerminal,
  ] = useState(undefined);

  useEffect(() => {
    if (transactionData && !currentTerminal && !amountFromParam) {
      // Selecting the first terminal as a connected terminal for transaction recovery
      dispatch(updateSelectedTerminal(terminals?.[0]?.id));
      setIsUnknownEftpostTransactionModalOpen(true);
    }
  }, [terminals]);
  const merchantReceipt = currentTerminal?.txFlow?.response?.data
    ?.merchantReceipt
    ? btoa(currentTerminal?.txFlow?.response?.data?.merchantReceipt)
    : "";

  const customerReceipt = currentTerminal?.txFlow?.response?.data
    ?.customerReceipt
    ? btoa(currentTerminal?.txFlow?.response?.data?.customerReceipt)
    : "";

  const serialNumber = currentTerminal?.serialNumber;

  useEffect(() => {
    if (transactionData) {
      // alert("transaction data is coming");
      dispatch(updateSelectedTerminal(terminals?.[0]?.id));
    }
  }, [termianls, amountFromParam, transactionData]);

  useEffect(() => {
    if (currentTerminal && transactionData) {
      setLastTransactionData({
        instanceId: selectedTerminal,
        transactionType: localStorage.getItem("transactionType"),
        orderRequest: {
          ...transactionData,
          TransactionRefId: posRefId,
        },
      });
    }
  }, [currentTerminal, transactionData]);

  useEffect(() => {
    if (currentTerminal?.txFlow?.success == "Success") {
      navigate(
        `/?isPaymentSuccess=true&posRefId=${localStorage.getItem(
          "posRefId"
        )}&merchantReceipt=${merchantReceipt}&customerReceipt=${customerReceipt}&serialNumber=${serialNumber}`
      );
      removeLastTransactionData();
      setIsUnknownEftpostTransactionModalOpen(false);
    } else if (currentTerminal?.txFlow?.success == "Failed") {
      if (customerReceipt && merchantReceipt) {
        navigate(
          `/?isPaymentSuccess=false&posRefId=${posRefId}&merchantReceipt=${merchantReceipt}&customerReceipt=${customerReceipt}&serialNumber=${serialNumber}`
        );
      }
      setWasTransactionCompletedInTerminal(false);
    }
  }, [currentTerminal]);

  console.log("current terminal", currentTerminal);
  console.log("selected terminal", selectedTerminal);
  return (
    <Modal
      centered
      show={isUnknowEftposTransactionModalOpen}
      backdropClassName="loginBackdrop"
      size={"md"}
    >
      <Modal.Body>
        <div
          style={{
            margin: "20px",
            background: "white",
            borderRadius: "10px",
          }}
        >
          <Result
            status="warning"
            title={
              wasTransactionCompletedInTerminal == undefined
                ? recoverTransactionMessageHeading
                : wasTransactionCompletedInTerminal == false
                ? IncompleteTransactionInTerminalMessageHeading
                : ""
            }
            subTitle={
              wasTransactionCompletedInTerminal == undefined
                ? recoverTransactionMessageSubHeading
                : wasTransactionCompletedInTerminal == false
                ? IncompleteTransactionInTerminalMessageSubHeading
                : ""
            }
          />

          <div className="d-flex justify-content-center gap-2 mt-3">
            {" "}
            {wasTransactionCompletedInTerminal == undefined && (
              <Button
                type="primary"
                onClick={() => {
                  recoverTransaction(
                    terminals?.[0]?.id,
                    posRefId,
                    localStorage.getItem("transactionType")
                  );
                }}
              >
                Recover Transaction
              </Button>
            )}
            {wasTransactionCompletedInTerminal == false && (
              <Button
                type="primary"
                onClick={() => {
                  navigate(
                    `/?isPayLastOrderClicked=true&posRefId${localStorage.getItem(
                      "posRefId"
                    )}&serialNumber=${serialNumber}`
                  );
                  setIsUnknownEftpostTransactionModalOpen(false);
                  removeLastTransactionData();
                }}
              >
                Pay Last Order
              </Button>
            )}
            {wasTransactionCompletedInTerminal == false && (
              <Button
                type="danger"
                onClick={() => {
                  navigate("/?isWebClosed=true");
                  removeLastTransactionData();
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnknownTransaction;
