/** @format */

// import { openNotificationWithIcon } from "../../components/Notification/Notification.tsx";

const initialState = {
  isLoading: false,
  error: null,
  success: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_ALL_PAIRING_CONFIGURATIONS_SECTION_LIST":
      return {
        ...state,
        getPaymentProviderLoading: true,
      };

    case "GET_ALL_PAIRING_CONFIGURATIONS_SECTION_LIST_SUCCESS":
      return {
        ...state,
        pairingConfigurations: payload,
        getPaymentProviderLoading: false,
      };
    case "GET_ALL_PAIRING_CONFIGURATIONS_SECTION_LIST_FAILURE":
      return {
        ...state,
        getPaymentProviderLoading: false,
      };

    case "GET_RELEASE_VERSION_REQUEST":
      return {
        ...state,
      };
    case "GET_RELEASE_VERSION_SUCCESS":
      localStorage.setItem("releaseVersion", payload.releaseVersion);
      return {
        ...state,
        releaseVersion: payload.releaseVersion,
      };
    case "GET_RELEASE_VERSION_FAILURE":
      return {
        ...state,
      };
    case "CLEAR_EDIT_DATA":
      return {
        ...state,
        editData: null,
      };
    default:
      return state;
  }
}
