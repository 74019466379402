/** @format */

import { Box, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import useStyles from "./index.styles";

import { Result } from "antd";
import { UnknownTransactionModalProps } from "./interfaces";

function UnknownTransactionModal({
  onFailedTransaction,
  onSuccessTransaction,
}: UnknownTransactionModalProps): React.ReactElement {
  const classes = useStyles();

  return (
    <Dialog aria-labelledby="spiModalTitle" open>
      <DialogContent className={classes.transactionProgressModalContnent}>
        <div
          style={{
            margin: "20px",
            background: "white",
            borderRadius: "10px",
          }}
        >
          <>
            <Result
              status="warning"
              title="Connection Lost !"
              subTitle="POS lost connection. Once reconnected to terminal, please reload app for transaction recovery."
            />
          </>
          <Box display="flex" justifyContent="space-evenly">
            <Button
              color="primary"
              variant="contained"
              className={classes.modalBtn}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UnknownTransactionModal;
