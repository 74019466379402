/** @format */

import "antd/dist/antd.css";
import "nprogress/nprogress.css";
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "react-notifications/lib/notifications.css";
import "react-progress-bar-plus/lib/progress-bar.css";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./Navbar.css";
import { store } from "./mx51/redux/store";
// import Store from "./Redux/store";
import "./index.css";
import PairPage from "./mx51/components/PairPage";
import HasPairing from "./pages/PayScreenForTab/HasPairing";
import PayScreenForTab from "./pages/PayScreenForTab/PayScreenForTab";
// import PairPage from "./mx51/components/PairPage";

function App() {
  return (
    <Provider store={store}>
      <div className="container-scroller">
        <Router>
          <React.Suspense fallback={null}>
            {/* <Layout> */}
            <Routes>
              <Route
                path="/"
                element={
                  // <ProtectedRoute>
                  <PayScreenForTab />
                  // </ProtectedRoute>
                }
              />
              <Route
                path="/pair-terminal-mx51"
                element={
                  // <ProtectedRoute>
                  <PairPage />
                  // </ProtectedRoute>
                }
              />
              <Route
                path="/hasPairing"
                element={
                  // <ProtectedRoute>
                  <HasPairing />
                  // </ProtectedRoute>
                }
              />
            </Routes>
            {/* </Layout> */}
          </React.Suspense>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
