/** @format */

import { InfoRounded } from "@material-ui/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PayNow from "../../mx51/components/PayNow";
import selectedTerminalIdSelector from "../../mx51/redux/reducers/SelectedTerminalSlice/selectedTerminalSliceSelector";
import {
  isPaired,
  pairedConnectedTerminalList,
  terminalInstance,
} from "../../mx51/redux/reducers/TerminalSlice/terminalsSliceSelectors";
import UnknownTransaction from "./UnknownTransaction";
const PayScreenForTab = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(search);

  const amountFromParam = queryParams.get("amount");
  const transactionType = queryParams.get("transactionType");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [
    isUnknowEftposTransactionModalOpen,
    setIsUnknownEftpostTransactionModalOpen,
  ] = useState(false);

  const navigate = useNavigate();
  const terminals = useSelector(pairedConnectedTerminalList);
  const selectedTerminal = useSelector(selectedTerminalIdSelector);

  const currentTerminal = useSelector(terminalInstance(selectedTerminal));

  // const terminalList = Object.values(termianls);
  const isTerminalPaired = useSelector(isPaired);

  useEffect(() => {
    if (amountFromParam && transactionType) {
      setIsModalOpen(true);
    }
  }, [amountFromParam, transactionType]);

  useEffect(() => {
    dispatch({
      type: "GET_RELEASE_VERSION_REQUEST",
    });
  }, []);

  return (
    <>
      <UnknownTransaction
        isUnknowEftposTransactionModalOpen={isUnknowEftposTransactionModalOpen}
        setIsUnknownEftpostTransactionModalOpen={
          setIsUnknownEftpostTransactionModalOpen
        }
      />

      <Modal
        show={isModalOpen}
        centered
        onHide={() => {
          setIsModalOpen(false);
          navigate("/isWebClosed/?isWebClosed=true");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Terminal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isTerminalPaired ? (
            <PayNow />
          ) : (
            <div>
              <div className="d-flex gap-2 align-items-center">
                <InfoRounded />
                <span>
                  {" "}
                  Please pair your POS to the terminal or check your network
                  connection{" "}
                </span>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(false);
                    navigate("/isWebClosed/?isWebClosed=true");
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PayScreenForTab;
